import Button from 'components/Button';
import './ProductCard.css';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import client from '../../util/datocms';
import Loading from 'components/Loading';

const ProductCard = ({ limit, filtered }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const query = `
          {
            allProdutos(first: 100) {
              id
              titulo
              categoria
              imagem{
                url
              }
            }
          }
        `;

        const { allProdutos } = await client.request(query);
        const limitedProducts = limit ? allProdutos.slice(0, 4) : allProdutos;
        setProducts(limitedProducts);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao obter os produtos:', error);
      }
    };

    fetchProducts();
  }, [limit]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : filtered.length === 0 ? (
        <p className="pesquisa-errada">A pesquisa não corresponde a nenhum produto.</p>
      ) : (
        <div className="produtos-area">
          {filtered.map((produto) => (
            <div className="caixa-produtos" key={produto.id}>
              <Link onClick={scrollToTop} to={`/produto/${produto.id}`} style={{ textDecoration: 'none' }}>
                <img className="imagem-produto" src={produto.imagem.url} alt={produto.titulo} />
              </Link>
              <p className="descricao-produto">{produto.titulo}</p>
              <Link onClick={scrollToTop} to={`/produto/${produto.id}`} style={{ textDecoration: 'none' }}>
                <Button>Ver produto</Button>
              </Link>
              <span className="categoria">{produto.categoria}</span>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ProductCard;
