import React from 'react';
import './Compra.css';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Produto from 'components/Produto';
import ProductCard from 'components/ProductCard';
import { useState } from 'react';
import { useEffect } from 'react';
import client from 'util/datocms';
import ContentContainer from 'components/ContentContainer';

export default function Compra() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const query = `
          {
            allProdutos {
              id
              titulo
              categoria
              imagem {
                url
              }
            }
          }
        `;

        const { allProdutos } = await client.request(query);
        setProducts(allProdutos);
      } catch (error) {
        console.error('Erro ao obter os produtos:', error);
      }
    };

    fetchProducts();
  }, []);


  const filtered = products
    .sort((a, b) => b.id - a.id)
    .slice(0, 4);

  return (
    <>
      <Header/>

      <Produto/>
      <ContentContainer>
        <h1 className='desc-title-produtos'>Produtos recomendados</h1>
        <ProductCard limit={true} filtered={filtered} />
      </ContentContainer>
      

      <Footer/>
    </>
  )
}
