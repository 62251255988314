import SearchBar from "components/SearchBar";
import "./Header.css"
import logo from 'assets/images/logo.png'
import { AiOutlineWhatsApp, AiOutlineInstagram, AiOutlineMail, AiOutlineClose } from 'react-icons/ai';
import { FaBars } from 'react-icons/fa'
import { Link } from "react-router-dom";
import { useState } from "react";

export default function Header(){

  const [mobile, setMobile] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return(
    <header>
      <div className="header-area">
        <Link onClick={scrollToTop} to="/">
          <img src={logo} alt="Logo Doce Arte Studio" width="150px" />
        </Link>

        <Link onClick={scrollToTop} to="/produtos" style={{ textDecoration: 'none' }}>
          <p className={`header-link-text header-text-desktop ${window.location.pathname === '/produtos' ? 'ativo' : ''}`}>
            Todos os produtos
          </p>
        </Link>
        <Link to="https://doceartestudio.com.br/" target="_blank" style={{textDecoration: 'none'}}>
          <p className="header-link-text header-text-desktop">Loja virtual</p>
        </Link>
        

        
        <nav>
          <ul className={mobile ? "nav-links-mobile" : "nav-links"}
          onClick={() => setMobile(true)}
          >
            <li className="nav-li">
            <SearchBar onSearch={() => setMobile(false)} />
            </li>

            <li className="nav-li nav-text-mobile">
              <Link onClick={scrollToTop} to="/produtos" style={{textDecoration: 'none'}}>
                <p className="header-link-text">Todos os produtos</p>
              </Link>
            </li>
            <li className="nav-li nav-text-mobile">
              <Link to="https://doceartestudio.com.br/" target="_blank" style={{textDecoration: 'none'}}>
                <p className="header-link-text">Loja virtual</p>
              </Link>
            </li>

            <li className="nav-li"><Link to="https://www.instagram.com/doceartestudio" target="_blank"><AiOutlineInstagram size="30px" color="#fff"/></Link></li>
            <li className="nav-li"><Link to="https://wa.me/5513981345668" target="_blank"><AiOutlineWhatsApp size="30px" color="#fff" target="_blank"/></Link></li>
            <li className="nav-li"><Link to="mailto:pati.guerrato@gmail.com" target="_blank"><AiOutlineMail size="30px" color="#fff" target="_blank"/></Link></li>
            
          </ul>
          
          <button className="mobile-menu-icon"
            onClick={() => setMobile(!mobile)}
          >
            {mobile ? <i><AiOutlineClose size="24px" color="#ffffff"/></i> : <i><FaBars size="24px" color="#ffffff"/></i>}
          </button>

        </nav>
      </div>
    </header>
  )
}