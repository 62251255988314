import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'App.css';
import HomePage from 'pages/HomePage';
import Produtos from 'pages/Produtos';
import Compra from 'pages/Compra';
import NaoEncontrada from 'pages/NaoEncontrada';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/produto/:id' element={<Compra />} />
        <Route path='/produtos' element={<Produtos />} />
        <Route path="/produtos/:termo" element={<Produtos/>} />
        <Route path='/produto' element={<Compra />} />
        <Route path='*' element={<NaoEncontrada />} />
      </Routes>
    </Router>
  );
}
