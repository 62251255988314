import React, { useEffect, useState } from 'react';
import SearchBar from 'components/SearchBar';
import Header from 'components/Header';
import './Produtos.css';
import Footer from 'components/Footer';
import ContentContainer from 'components/ContentContainer';
import PageArea from 'components/PageArea';
import Filtro from 'components/Filtro';
import ProductCard from 'components/ProductCard';
import client from 'util/datocms';
import { useLocation } from 'react-router-dom';

export default function Produtos() {
  const [products, setProducts] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [activeCategory, setActiveCategory] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const query = `
          {
            allProdutos(first: 100)  {
              id
              titulo
              categoria
              imagem {
                url
              }
            }
          }
        `;

        const { allProdutos } = await client.request(query);
        setProducts(allProdutos);
        setFiltered(allProdutos);
      } catch (error) {
        console.error('Erro ao obter os produtos:', error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const filteredProducts = products.filter(
      (product) =>
        product.categoria.includes(activeCategory) &&
        product.titulo.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFiltered(filteredProducts);
  }, [activeCategory, products, searchQuery]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchParam = params.get('search');
    setSearchQuery(searchParam || '');
  }, [location.search]);

  return (
    <>
      <Header>
        <SearchBar />
      </Header>
      <PageArea>
        <h1 className="prod-title">Produtos</h1>
        <Filtro
          products={products}
          setFiltered={setFiltered}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
        <ContentContainer>
          <section className='page-prod-area'>
            <ProductCard limit={false} filtered={filtered} />
          </section>
          
        </ContentContainer>
      </PageArea>
      <Footer />
    </>
  );
}
