import React, { useState, useEffect } from 'react';
import './SearchBar.css';
import { AiOutlineSearch, AiOutlineClose } from 'react-icons/ai';
import { useNavigate, useLocation } from 'react-router-dom';

const SearchBar = ({ onSearch }) => {
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchParam = params.get('search');
    setSearchText(searchParam || '');
  }, [location.search]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchText.trim() !== '') {
      navigate(`/produtos?search=${encodeURIComponent(searchText)}`);
      window.scrollTo(0, 0);
      if (window.location.pathname === '/produtos') {
        onSearch();
      }
    }
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText('');
    navigate('/produtos');
    window.scrollTo(0, 0);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <form className="search-bar" onSubmit={handleSearch}>
      <input
        type="search"
        className="search-text"
        placeholder="Pesquisar"
        value={searchText}
        onChange={handleInputChange}
        required
      />
      
      {searchText && (
        <button  type="button" className="clear-button" onClick={handleClearSearch}>
          <AiOutlineClose size="20px" color="#19C8DB" />
        </button>
      )}
      <button onClick={scrollToTop} type="submit" className="search-button">
        <AiOutlineSearch size="30px" color="#19C8DB" />
      </button>
    </form>
  );
};

export default SearchBar;
