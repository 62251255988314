import './Footer.css';
import logo from 'assets/images/logo.png';
import { Link } from 'react-router-dom'

export default function Footer(){
  return(
    <footer>
      <div className='footer-area'>
        <div className='footer-content'>
          <img src={logo} alt="Logo Doce Arte Studio" width="200px" />
          <p className="copyright">&copy; Doce Arte Studio - Desenvolvido por <Link to="https://www.linkedin.com/in/henriqueguerrato/" target='_blank' style={{ textDecoration: 'none', color: '#fff' }}>Henrique Guerrato</Link> </p>
        </div>
      </div>
    </footer>
  );
}